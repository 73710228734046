<template>
  <div>
    <el-autocomplete
        :placeholder="placeholder ? placeholder : $t('ccompanyPlaceholder')"
        v-model="company"
        :fetch-suggestions="queryCompany"
        size="small"
        :trigger-on-focus="false"
        style="width: 100%"
        clearable
        :validate-event="false"
    ></el-autocomplete>
  </div>
</template>

<script>
export default {
  name: "expCompanySelect",
  props: ["countryId", "cityId", "value", "placeholder", "pavilion_id","type"],
  // type = 1：展馆里搜索企业
  // type = 2： 直通海外搜索企业
  // type = 3： 展商
  data() {
    return { company: "" };
  },
  computed: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.company = this.value;
    });
  },
  watch: {
    countryId() {
      this.company = "";
    },
    cityId() {
      this.company = "";
    },
    company(nv) {
      this.$emit("input", nv);
    },
  },
  methods: {
    async queryCompany(queryString, cb) {
      try {
        let parmas = {
          limit: 100,
          start: 0,
        };
        if (this.countryId) {
          parmas.country_id = this.countryId;
        }
        if (this.cityId) {
          parmas.city_id = this.cityId;
        }
        let data
        if (this.type == 1) {
          parmas.pavilion_id = this.pavilion_id;
          parmas.company_name = queryString;
          data = await this.$store.dispatch(
              "booth/expCompanyGetCompanyNameList",
              parmas
          );
        }
        if (this.type == 2) {
          parmas.area = 0;
          parmas.apply_type = '4';
        }
        if (this.type == 3) {
          parmas.apply_type = '4';
        }
        if (this.type == 2 || this.type == 3) {
          parmas.meeting_id = this.MEETING_ID;
          parmas.name = queryString;
          parmas.is_paid=0
          data = await this.$store.dispatch(
              "booth/getExpoExhibitorList",
              parmas
          );
        }
        if (data.success) {
          if (this.type == 1) {
            data.data.forEach((ee) => {
              ee.value =
                  this.LOCALE == "en"
                      ? ee.name_en
                      ? ee.name_en
                      : ee.name_zh
                      : ee.name_zh
                      ? ee.name_zh
                      : ee.name_en;
            });
          } else {
            data.data.forEach((ee) => {
              ee.value =
                  this.LOCALE == "en"
                      ? ee.company_name_en
                      ? ee.company_name_en
                      : ee.company_name_zh
                      : ee.company_name_zh
                      ? ee.company_name_zh
                      : ee.company_name_en;
            });
          }

          cb(data.data);
        }
      } catch {}
    },
    clearInfo() {
      this.company = "";
    },
  },
  components: {},
};
</script>

<style scoped lang="less">
</style>
