<template>
  <div>
    <el-dialog
      :title="(company_Info.name_en || company_Info.name_zh || '-') "
      :visible.sync="dialogVisible"
      width="1000px"
      @close="close"
    >
      <div class="aboutAs">
        <div class="companyInfo">
          <div class="desc">
            <pre v-if="LOCALE == 'en'">{{
              company_Info.profile_en || company_Info.profile_zh || ""
            }}</pre>
            <pre
              v-else
              v-html="company_Info.profile_zh || company_Info.profile_en || ''"
            ></pre>
          </div>
          <div class="info clearfix">
            <div class="item fl clearfix">
              <div class="fontweight title fl">{{ $t("established") }}</div>
              <div class="content fl textOverflow">
                {{ company_Info.found_year | textFormat }}
              </div>
            </div>
            <div class="item fl clearfix">
              <div class="fontweight title fl">{{ $t("registered") }}</div>
              <div
                class="fl textOverflow"
                :class="LOCALE == 'en' ? 'space' : 'content'"
              >
                {{ company_Info.registered_capital | textFormat }}
              </div>
            </div>
            <div class="item fl clearfix">
              <div class="fontweight title fl">{{ $t("enterprisescale") }}</div>
              <div class="content fl textOverflow">
                {{ company_Info.scale | staffSizeFormat(LOCALE) }}
              </div>
            </div>
            <div class="item fl clearfix">
              <div class="fontweight title fl">{{ $t("phone") }}</div>
              <div class="content1 fl textOverflow">
                <span v-if="IS_LOGIN">{{
                  company_Info.telephone | phoneFormat(company_Info.area_code)
                }}</span>
                <span
                  v-else
                  @click="$router.push('/sign')"
                  class="mainColor cursor"
                  >{{ $t("yetLogin") }}</span
                >
              </div>
              <div
                class="fl copyImg cursor"
                v-if="IS_LOGIN"
                @click="copyText(company_Info.telephone, 1)"
              >
                <i class="el-icon-document-copy mainColor"></i>
              </div>
            </div>
            <div class="item fl clearfix">
              <div class="fontweight title fl">{{ $t("email") }}</div>
              <div class="content1 fl textOverflow">
                <span v-if="IS_LOGIN">{{
                  company_Info.email | textFormat
                }}</span>
                <span
                  v-else
                  @click="$router.push('/sign')"
                  class="mainColor cursor"
                  >{{ $t("yetLogin") }}</span
                >
              </div>
              <div
                class="fl copyImg cursor"
                v-if="IS_LOGIN"
                @click="copyText(company_Info.email, 2)"
              >
                <i class="el-icon-document-copy mainColor"></i>
              </div>
            </div>
            <div class="item fl clearfix">
              <div class="fontweight title fl">{{ $t("companyWebsite") }}</div>
              <div class="content1 fl textOverflow">
                {{ company_Info.website | textFormat }}
              </div>
              <div
                class="fl copyImg cursor"
                @click="copyText(company_Info.website, 3)"
              >
                <i class="el-icon-document-copy mainColor"></i>
              </div>
            </div>
            <div class="item1 fl clearfix">
              <div class="fontweight title fl">{{ $t("companyAddress") }}</div>
              <div class="content fl textOverflow">
                <!-- {{ company_Info.country_id | countryFormat(LOCALE) | lineFormat }}
              {{
                company_Info.region_en
                  | priorFormat(company_Info.region_zh, LOCALE)
                  | lineFormat
              }} -->
                {{
                  company_Info.address_en
                    | priorFormat(company_Info.address_zh, LOCALE)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["companyId"],
  data() {
    return {
      dialogVisible: false,
      company_Info: {},
    };
  },
  mounted() {
    this.getCompanyDetail();
  },
  watch:{
    companyId(val, oldVal){//普通的watch监听
       this.getCompanyDetail();
     },
  },
  methods: {
    close() {
      this.$emit("dialogClose");
    },
    copyText(text, type) {
      if (!text) return;
      const input = document.createElement("textarea");
      document.body.appendChild(input);
      input.value = `${text}`;
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.$message.success(this.$t("copySuccess"));
    },
    async getCompanyDetail() {
      let id = this.companyId || "";
      let params = {
        company_id: id,
        visit_company_id: this.USER_INFO ? this.USER_INFO.company_id : "",
        visit_user_id: this.USER_INFO ? this.USER_INFO.id : "",
      };
      try {
        let data = await this.$store.dispatch(
          "baseStore/getBoothCompanyInfo",
          params
        );
        if (data.success) {
          this.company_Info = data.data;
        }
        this.initComplete();
      } catch (error) {}
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog__header{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 80px;
}
.aboutAs {
  width: 943px;
  background: #fff;
  padding: 0 20px;
  margin-bottom: 12px;
  .titleBox {
    font-size: 16px;
    font-weight: 600;
    line-height: 60px;
    border-bottom: 1px solid #dcdfe6;
  }
  .companyInfo {
    padding: 18px 0;
    .desc {
      color: #3c5058;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 12;
      -webkit-box-orient: vertical;
    }
    .info {
      margin-top: 24px;
      .item {
        width: 33%;
        margin-bottom: 24px;
        .title {
          min-width: 80px;
        }
        .content {
          max-width: 200px;
          margin-left: 10px;
        }
        .content1 {
          max-width: 180px;
        }
        .copyImg {
          width: 12px;
          height: 12px;
          margin: 0 10px;
        }
      }
      .item1 {
        width: 100%;
        .title {
          min-width: 80px;
        }
        .content {
          margin-left: 10px;
          max-width: 80%;
        }
      }
    }
  }
}
.fontweight {
  font-weight: bold;
}
.space {
  max-width: 140px;
  margin-left: 10px;
}
pre {
  width: 90%;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>