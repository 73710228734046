<template>
  <div class="companyList_searchHeaderList content_area">
    <div class="clearfix">
      <CountrySelect
        clearable
        class="fl"
        style="width: 250px"
        v-model="search.country"
        :locale="LOCALE"
        :placeTitle="$t('countryPlaceholder')"
        :hiddenChina="hiddenChina"
        :type="1"
      ></CountrySelect>
      <CitySelect
        clearable
        class="fl"
        style="width: 250px; margin: 0 16px"
        v-model="search.city"
        size="small"
        :locale="LOCALE"
        :countryId="search.country"
        :placeTitle="$t('cityPlaceholder')"
         :type="1"
      ></CitySelect>
      <CompanySelect
        clearable
        class="fl"
        v-model="search.company"
        style="width: 320px"
        :countryId="search.country"
        :cityId="search.city"
        :pavilion_id="pavilion_id"
        :type="type"
      ></CompanySelect>
      <!-- <el-input
        v-if="searchType === 1"
        v-model="search.boothId"
        :placeholder="$t('enterBoothNumber')"
        size="small"
        style="width: 195px; margin-left: 16px"
      ></el-input> -->
      <el-input
        v-if="searchType === 2"
        v-model="search.name"
        :placeholder="$t('pleaseEnterName')"
        size="small"
        style="width: 195px; margin-left: 16px"
      ></el-input>
      <div class="fr filedownBtn" v-if="downBtn">
        <el-button type="primary" size="small" @click="downFile">
          {{$t('downexhibitionexpress')}}
        </el-button>
      </div>
      <el-button type="primary" @click="searchObj" class="fr" size="small">{{
        LOCALE == "en" ? "Search" : "搜索"
      }}</el-button>
      
    </div>
    <!-- <div class="midSearch clearfix">
      <p style="" class="fl">
        {{ $t("companyScreen") }}
      </p>
      <el-popover
        placement="right-start"
        width="400"
        trigger="click"
        v-model="industryCheck"
        @show="industryShow"
      >
        <div>
          <el-cascader
            v-model="industry"
            :options="IndustryData"
            style="width: 100%; margin: 20px 0"
            :placeholder="$t('pleaseSelect')"
            size="small"
            ref="industryCasader"
          ></el-cascader>
          <div style="text-align: right; margin: 0">
            <el-button
              size="small"
              type="text"
              @click="industryCheck = false"
              >{{ $t("cancel") }}</el-button
            >
            <el-button type="primary" size="small" @click="searchIndustry">{{
              $t("confirm")
            }}</el-button>
          </div>
        </div>
        <el-button class="fl screenBtn" slot="reference" size="small">{{
          $t("companyIndustry")
        }}</el-button>
      </el-popover>
      <el-popover
        @show="showBusiness"
        placement="right-start"
        width="600"
        trigger="click"
        v-model="bussinessCheck"
      >
        <div>
          <BussinessClassification
            v-if="bussinessCheck"
            @closeClass="bussinessCheck = false"
            @saveClass="searchBussiness"
            class="businessBox"
            :classification="classification"
            :isSelect="business_classification"
            :number="5"
          ></BussinessClassification>
        </div>
        <el-button class="fl screenBtn" slot="reference" size="small">{{
          $t("companyGoodBusiness")
        }}</el-button>
      </el-popover>
      <el-popover
        placement="right-start"
        width="600"
        trigger="click"
        v-model="majorShow"
      >
        <div>
          <majorLine
            v-if="majorShow"
            :number="5"
            @cancelLine="majorShow = false"
            @saveLine="saveLine"
            :majorLine="majorLineData"
            :isSelect="havaCheckmajor"
          ></majorLine>
        </div>
        <el-button class="fl screenBtn" slot="reference" size="small">{{
          $t("BussinessMajor")
        }}</el-button>
      </el-popover>
    </div> -->
    <div class="haveSelect clearfix" v-if="searchResult.length">
      <p
        class="fl"
        style="max-width: 100px; text-align: right; line-height: 32px"
      >
        {{ $t("companyhaveSeletc") }} ：
      </p>
      <div class="fl" style="width: 1150px">
        <el-tag
          :class="tag.parentKey"
          v-for="(tag, index) in searchResult"
          style="margin-right: 10px; margin-bottom: 10px"
          :key="index"
          disable-transitions
          closable
          @close="closetag(tag, index)"
          >{{ tag.label }}</el-tag
        >
      </div>
    </div>
  </div>
</template>

<script>
import CountrySelect from "~/baseComponents/meetingComponents/CountrySelect";
import CitySelect from "~/baseComponents/meetingComponents/CitySelect";
import CompanySelect from "~osm/components/booth/expCompanySelect";
import { certificateList, Industry } from "~/baseUtils/enumValue";
import { shippingLine } from "~bac/utils/enumValue";
import { industryFormat } from "~/basePlugins/filters";
import BussinessClassification from "@/baseComponents/BussinessClassification";
import { getBusinessObject } from "~bac/plugins/filters";
import majorLine from "~bac/components/company/business/majorLine";
export default {
  props: {
    // 1是搜索展台位，2是搜索名字
    searchType: {
      type: Number,
      default: 1,
    },
    pavilion_id: {
      type: Number | String,
      default: "",
    },
    hiddenChina: {
      type: Boolean,
      default: false,
    },
    // type = 1：展馆里搜索企业
    // type = 2： 直通海外搜索企业
    // type = 3： 展商

    type: {
      type: String | Number,
      default: 1,
    },
    downBtn:{
      type:String|Number,
      default:0
    }
  },
  data() {
    return {
      certificateList: certificateList.map((item) => {
        item.value = item.value + "";
        return item;
      }),
      search: {
        country: null,
        city: null,
        company: "",
        boothId: "",
        association: "",
        industry: "",
        business_classification: "",
        major: "",
        name: "",
      },
      IndustryData: [],
      majorLineData: shippingLine,
      association: [],
      industry: [],
      business_classification: {
        Convertional: [],
        "Dangerous Cargo": [],
        "Other convertional": [],
        "Particular Cargo": [],
        Services: [],
      },
      majorShow: false,
      bussinessCheck: false,
      associationCheck: false,
      industryCheck: false,
      classification: [],
      havaCheckmajor: [],
      havaCheckCertificate: [], //  中转的协会
      searchResult: [],
    };
  },

  created() {},
  mounted() {
    console.log(this.ENROLL_INFO)
    if (this.LOCALE == "en") {
      Industry.forEach((element) => {
        element.label = element.label_en;
        element.children.forEach((ele) => {
          ele.label = ele.label_en;
        });
      });
    } else {
      Industry.forEach((element) => {
        element.label = element.label_zh;
        element.value = element.label_zh;
        element.children.forEach((ele) => {
          ele.label = ele.label_zh;
        });
      });
    }
    this.IndustryData = Industry;
  },
  watch: {},
  methods: {
    // 获取已报名的协会
    async getCertificateList() {},
    deleteCheck(item, index) {
      this.havaCheckCertificate.splice(index, 1);
    },
    addCertificateList(item) {
      if (this.havaCheckCertificate.length > 4) {
        this.$message({
          message: this.LOCALE == "en" ? "Select up to five" : "最多选择五个",
          type: "warning",
        });
        return;
      }
      let arr = this.havaCheckCertificate;
      let isHave = arr.find((res) => {
        return res.value == item.value;
      });
      if (isHave) {
        this.$message({
          showClose: true,
          message: this.LOCALE == "en" ? "Added" : "已添加",
          type: "warning",
        });
        return;
      } else {
        this.havaCheckCertificate.push(item);
      }
    },
    searchObj() {
      this.$emit("search", this.search);
      if (this.type == 1) {
        this.$log({
          type: 1,
          id: "Hall.business.search",
          topic: "Hall.business",
          content: JSON.stringify({
            country_id: this.search.country,
            city_id: this.search.city,
            company_name: this.search.company,
            booth_no: this.search.boothId,
          }),
        });
      }
      if (this.type == 2) {
        this.$log({
          type: 1,
          id: "overseas.search",
          topic: "overseas",
          content: JSON.stringify({
            country_id: this.search.country,
            city_id: this.search.city,
            company_name: this.search.company,
            booth_no: this.search.boothId,
          }),
        });
      }
    },
    closetag(tag, index) {
      this.searchResult.splice(index, 1);
      if (tag.type === 1) {
        const deleteIndex = this.association.findIndex((res) => {
          // 不写return返回的是-1，谜
          return res.value == tag.value;
        });
        if (deleteIndex !== -1) {
          this.association.splice(deleteIndex, 1);
        }
      }
      if (tag.type === 2) {
        this.industry = [];
      }
      if (tag.type === 3) {
        let classConIndex = this.business_classification.Convertional.findIndex(
          (res) => {
            return res.id == tag.value;
          }
        );
        if (classConIndex !== -1) {
          this.business_classification.Convertional.splice(classConIndex, 1);
        }

        let classDcIndex = this.business_classification[
          "Dangerous Cargo"
        ].findIndex((res) => {
          return res.id == tag.value;
        });
        if (classDcIndex !== -1) {
          this.business_classification["Dangerous Cargo"].splice(
            classDcIndex,
            1
          );
        }

        let classOcoIndex = this.business_classification[
          "Other convertional"
        ].findIndex((res) => {
          return res.id == tag.value;
        });
        if (classOcoIndex !== -1) {
          this.business_classification["Other convertional"].splice(
            classOcoIndex,
            1
          );
        }

        let classPcIndex = this.business_classification[
          "Particular Cargo"
        ].findIndex((res) => {
          return res.id == tag.value;
        });
        if (classPcIndex !== -1) {
          this.business_classification["Particular Cargo"].splice(
            classPcIndex,
            1
          );
        }

        let classCsIndex = this.business_classification.Services.findIndex(
          (res) => {
            return res.id == tag.value;
          }
        );
        if (classCsIndex !== -1) {
          this.business_classification.Services.splice(classCsIndex, 1);
        }
      }
      if (tag.type === 4) {
        const deleteIndex = this.havaCheckmajor.findIndex((res) => {
          // 不写return返回的是-1，谜
          return res.id == tag.value;
        });
        if (deleteIndex !== -1) {
          this.havaCheckmajor.splice(deleteIndex, 1);
        }
      }
      this.disposeReqFn();
    },
    async showBusiness() {
      this.classification = getBusinessObject([], true);
    },
    searchByAssociation() {
      this.association = this.havaCheckCertificate;
      this.concatSearchResult();
      this.associationCheck = false;
      this.disposeReqFn();
    },
    searchIndustry() {
      this.concatSearchResult();
      this.industryCheck = false;
      this.disposeReqFn();
    },
    saveLine(e) {
      if (e.length > 0) {
        this.havaCheckmajor = [];
        this.majorLineData.forEach((item) => {
          e.forEach((id) => {
            if (item.id === id) {
              this.havaCheckmajor.push(item);
            }
          });
        });
      }
      this.concatSearchResult();
      this.majorShow = false;
      this.disposeReqFn();
    },
    searchBussiness(e) {
      if (e.length > 0) {
        this.business_classification = {
          Convertional: [],
          "Dangerous Cargo": [],
          "Other convertional": [],
          "Particular Cargo": [],
          Services: [],
        };
        if (this.classification.Convertional.length > 0) {
          this.classification.Convertional.forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification.Convertional.push(item);
              }
            });
          });
        } else {
          this.business_classification.Convertional = [];
        }

        if (this.classification["Dangerous Cargo"].length > 0) {
          this.classification["Dangerous Cargo"].forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification["Dangerous Cargo"].push(item);
              }
            });
          });
        } else {
          this.business_classification["Dangerous Cargo"] = [];
        }

        if (this.classification["Other convertional"].length > 0) {
          this.classification["Other convertional"].forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification["Other convertional"].push(item);
              }
            });
          });
        } else {
          this.business_classification["Other convertional"] = [];
        }

        if (this.classification["Particular Cargo"].length > 0) {
          this.classification["Particular Cargo"].forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification["Particular Cargo"].push(item);
              }
            });
          });
        } else {
          this.business_classification["Particular Cargo"] = [];
        }

        if (this.classification.Services.length > 0) {
          this.classification.Services.forEach((item) => {
            e.forEach((id) => {
              if (item.id === id) {
                this.business_classification.Services.push(item);
              }
            });
          });
        } else {
          this.business_classification.Services = [];
        }
      }
      this.concatSearchResult();
      this.bussinessCheck = false;
      this.disposeReqFn();
    },
    certificateShow() {
      if (this.association.length > 0) {
        let arr = [];
        certificateList.forEach((ee) => {
          this.association.forEach((eee) => {
            if (ee.value == eee.value) {
              arr.push(ee);
            }
          });
        });
        this.havaCheckCertificate = arr;
      } else {
        this.association = [];
        this.havaCheckCertificate = [];
      }
    },
    industryShow() {
      this.$nextTick(async () => {
        this.$refs.industryCasader.$children[0].$refs.input.click();
      });
    },
    // 合并标签
    concatSearchResult() {
      this.searchResult = [];
      let certificateArr = [];
      let industry = [];
      let bussiness = [];
      let havaCheckmajor = [];
      if (this.association.length > 0) {
        this.association.forEach((item) => {
          let obj = {
            value: item.value,
            parentKey: "certificate",
            label: item.name,
            type: 1,
          };
          certificateArr.push(obj);
        });
      }
      if (this.industry.length > 0) {
        let label = industryFormat(this.industry[1], this.LOCALE);
        let arr = label.split("-");
        let obj = {
          value: this.industry[1],
          parentKey: "industry",
          label: this.industry[0] + "/" + arr[1],
          type: 2,
        };
        industry.push(obj);
      }
      let bussinessConcat = this.business_classification.Convertional.concat(
        this.business_classification["Dangerous Cargo"],
        this.business_classification["Other convertional"],
        this.business_classification["Particular Cargo"],
        this.business_classification.Services
      );
      if (bussinessConcat.length > 0) {
        bussinessConcat.forEach((item) => {
          let obj = {
            value: item.id,
            parentKey: "business_classification",
            label: this.LOCALE === "en" ? item.desc_en : item.desc_zh,
            type: 3,
          };
          bussiness.push(obj);
        });
      }
      if (this.havaCheckmajor.length > 0) {
        this.havaCheckmajor.forEach((item) => {
          let obj = {
            value: item.id,
            parentKey: "lanes",
            label: item.name,
            type: 4,
          };
          havaCheckmajor.push(obj);
        });
      }
      this.searchResult = this.searchResult.concat(
        certificateArr,
        industry,
        bussiness,
        havaCheckmajor
      );
    },
    // 处理参数
    disposeReqFn() {
      if (this.association.length > 0) {
        let arr = this.association.map((item) => item.value);
        this.search.association = arr.join();
      } else {
        this.search.association = "";
      }

      if (this.industry.length > 0) {
        this.search.industry = this.industry[1];
      } else {
        this.search.industry = "";
      }

      let bussinessConcat = this.business_classification.Convertional.concat(
        this.business_classification["Dangerous Cargo"],
        this.business_classification["Other convertional"],
        this.business_classification["Particular Cargo"],
        this.business_classification.Services
      );
      if (bussinessConcat.length > 0) {
        let arr = bussinessConcat.map((item) => item.id);
        this.search.business_classification = arr.join();
      } else {
        this.search.business_classification = "";
      }

      if (this.havaCheckmajor.length > 0) {
        let arr = this.havaCheckmajor.map((item) => item.id);
        this.search.major = arr.join();
      } else {
        this.search.major = "";
      }
      this.$emit("search", this.search);
      if (this.type == 1) {
        this.$log({
          type: 1,
          id: "Hall.business.screening",
          topic: "Hall.business",
        });
      }
      if (this.type == 2) {
        if (this.search.industry) {
          this.$log({
            type: 1,
            id: "overseas.screening.industry",
            topic: "overseas",
            content: JSON.stringify({
              industry: this.search.industry,
            }),
          });
        }
        if (this.search.business_classification) {
          this.$log({
            type: 1,
            id: "overseas.screening.services",
            topic: "overseas",
            content: JSON.stringify({
              services: this.search.business_classification,
            }),
          });
        }
        if (this.search.major) {
          this.$log({
            type: 1,
            id: "overseas.screening.lanes",
            topic: "overseas",
            content: JSON.stringify({
              lanes: this.search.major,
            }),
          });
        }
      }
    },
    downFile(){
      if(!this.IS_LOGIN){
        this.$router.push("/sign");
        return 
      }
      if(this.USER_INFO && !this.ENROLL_INFO.is_user_enroll){
        this.$message({
          message: this.$t("yetExpo"),
          type: 'warning'
        });
        return 
      }
      if(this.USER_INFO && this.ENROLL_INFO.is_user_enroll && !this.ENROLL_INFO.audit_status){
        this.$message({
          message: this.$t("waitAuith"),
          type: 'warning'
        });
        return
      }
      if(this.ENROLL_INFO.apply_type !=4&&this.ENROLL_INFO.is_paid === 1&&this.ENROLL_INFO.audit_status ===1){
        window.open("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldFiles/expoMeeting/number_info.xls")
      }else{
        if(this.ENROLL_INFO.apply_type ==4){
          this.$message({
          message: this.$t("dwnFilelistByname"),
          type: 'warning'
        });
        return
        }
        this.$message({
          message: this.$t("exp_not_tongguo"),
          type: 'warning'
        });
      }
    }
  },

  components: {
    CountrySelect,
    CitySelect,
    BussinessClassification,
    CompanySelect,
    majorLine,
  },
};
</script>

<style scoped lang="less">
.companyList_searchHeaderList {
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 35px 25px;

  .midSearch {
    padding: 20px 0;
    p {
      line-height: 32px;
      font-size: 14px;

      padding-right: 10px;
    }
  }
  .screenBtn {
    margin-right: 15px;
  }
  .haveSelect {
  }
}
.businessBox {
  /deep/ .bussinessContent {
    padding: 0;
  }
  /deep/ .bussinessListTitle {
    line-height: 30px;
  }
}
.certificate {
  background-color: rgba(240, 250, 255, 0.9);
  border: 1px solid rgba(145, 213, 255, 1);
  color: #91d5ff;
  /deep/ .el-tag__close {
    color: #91d5ff;
  }
  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #1890ff;
  }
}
.industry {
  background-color: rgba(237, 243, 254, 1);
  border: 1px solid rgba(189, 210, 253, 1);
  color: #2c6df2;
  /deep/ .el-tag__close {
    color: #2c6df2;
  }
  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #1890ff;
  }
}
.business_classification {
  background-color: #f3fffa;
  border: 1px solid rgba(189, 239, 219, 1);
  color: #0dad6d;
  /deep/ .el-tag__close {
    color: #0dad6d;
  }
  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #0dad6d;
  }
}
.wanted_business {
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  color: #666666;
  /deep/ .el-tag__close {
    color: #666666;
  }
  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #666666;
  }
}
.interest_city {
  background-color: #fffae9;
  border: 1px solid #fbe5a2;
  color: #f7c52b;
  /deep/ .el-tag__close {
    color: #f7c52b;
  }
  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #f7c52b;
  }
}
.media_flag {
  background-color: #eeffff;
  border: 1px solid #aad8d8;
  color: #5c9d9d;
  /deep/ .el-tag__close {
    color: #5c9d9d;
  }
  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #5c9d9d;
  }
}
.found_year {
  background-color: #fff5ed;
  border: 1px solid #ffd8b8;
  color: #ff943d;
  /deep/ .el-tag__close {
    color: #ff943d;
  }
  /deep/ .el-tag__close:hover {
    color: #fff;
    background-color: #ff943d;
  }
}
.newcertificateList {
  p {
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    white-space: nowrap;
    line-height: 30px;
    cursor: pointer;
    &:hover {
      background: #f2f2f2;
    }
  }
  .flexBox {
    i {
      font-size: 12px;
      line-height: 30px;
      cursor: pointer;
      &:hover {
        color: #0083f6;
      }
    }
  }
}
.filedownBtn {
  margin-left: 13px;
  a {
    color: #fff;
  }
}
</style>
