<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    class="cardDialog"
    width="500px"
    center
  >
    <div :class="[show_more ? 'show_true' : 'show_false']" class="myBox">
      <userMsg :item="item"></userMsg>
      <div v-if="show_more" class="show_box">
        <!-- 擅长业务 -->
        <div>
          <h1>{{ $t("Good_at_business_exp") }}</h1>
          <div class="green_box">
            <span
              v-for="(ite, i) in item.business_classification_info[
                'Convertional'
              ]"
              :key="i"
            >
              #{{ ite.desc_en | priorFormat(ite.desc_zh, LOCALE) }}
            </span>

            <span
              v-for="(it, key) in item.business_classification_info[
                'Particular Cargo'
              ]"
              :key="key"
            >
              #{{ it.desc_en | priorFormat(it.desc_zh, LOCALE) }}
            </span>

            <span
              v-for="(itm, k) in item.business_classification_info[
                'Dangerous Cargo'
              ]"
              :key="k"
            >
              #{{ itm.desc_en | priorFormat(itm.desc_zh, LOCALE) }}
            </span>
          </div>
        </div>
        <!-- 航线 -->
        <div>
          <h1>{{ $t("BussinessMajor") }}</h1>
          <div class="green_box" v-if="item.shipping_line_info.length != 0">
            <span v-for="(item, i) in item.shipping_line_info" :key="i">{{
              item.name_en | priorFormat(item.name_cn, LOCALE)
            }}</span>
          </div>
          <div v-else>-</div>
        </div>
        <!-- 个人介绍 -->
        <div class="Personal_introduction">
          <h1>{{ $t("Personal_introduction") }}</h1>
          <p>{{ item.user_profile | textFormat }}</p>
        </div>
        <!-- 联系方式 -->
        <div class="lxfs">
          <div>
            <label>{{ $t("company_net") }}</label>
            <span v-if="item.foreign_info && item.foreign_info.website">{{
              item.foreign_info.website | textFormat
            }}</span>
            <span v-else>{{ item.website | textFormat }}</span>
          </div>
          <div>
            <label>{{ $t("wechat") }}</label
            ><span>{{ item.wechat | textFormat }}</span
            ><i @click="copyText('复制文本')" class="el-icon-document-copy"></i>
          </div>
          <div>
            <label>Facebook</label><span>{{ item.facebook | textFormat }}</span
            ><i @click="copyText('复制文本')" class="el-icon-document-copy"></i>
          </div>
          <div>
            <label>{{ $t("linkedIn") }}</label
            ><span>{{ item.linkedin | textFormat }}</span
            ><i @click="copyText('复制文本')" class="el-icon-document-copy"></i>
          </div>
          <div>
            <label>Skype</label><span>{{ item.skype | textFormat }}</span
            ><i @click="copyText('复制文本')" class="el-icon-document-copy"></i>
          </div>
        </div>
      </div>
      <!-- 企业视频 -->
      <div class="videoList" v-if="show_more">
        <div class="item" v-for="(item, index) in item.video_list" :key="index">
          <div class="img pointer img-wrap">
            <el-image
              :src="item.cover_url"
              fit="scale-down"
              class="item"
            ></el-image>
            <div class="mark">
              <i
                class="el-icon-video-play cursor"
                @click="handleContent(item)"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_footer_box">
        <el-button
          v-if="USER_INFO.id"
          :disabled="item.user_id == USER_INFO.id"
          @click="ExchangeCard"
          type="primary"
          size="mini"
          class="btn_footer"
          >{{ $t("cardExchange") }}</el-button
        >
        <el-button
          v-else
          type="primary"
          size="mini"
          class="btn_footer"
          @click="$router.push('/sign')"
          >{{ $t("cardExchange") }}</el-button
        >
      </div>
    </div>
    <div slot="footer">
      <div class="box" @click="show_more = !show_more" v-if="USER_INFO.id">
        <span>{{ show_more ? $t("packup") : $t("Expand_more") }}</span>
        <i
          :class="[
            show_more ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right',
          ]"
          class="i_rotate"
        ></i>
      </div>
      <div class="box" v-else @click="$router.push('/sign')">
        <span>{{ show_more ? $t("packup") : $t("Expand_more") }}</span>
        <i
          :class="[
            show_more ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right',
          ]"
          class="i_rotate"
        ></i>
      </div>
    </div>
    <videoPlay ref="vidoePlay"></videoPlay>
  </el-dialog>
</template>
<script>
import userMsg from "~exp/components/directOverseas/userMsg";
import videoPlay from "@/baseComponents/videoPlay";
export default {
  components: {
    userMsg,
    videoPlay,
  },
  data() {
    return {
      dialogFormVisible: false,
      show_more: false,
      row: {},
      item: {},
    };
  },
  mounted() {
    this.$GLOBALEVENT.$on("OPENUSERCard", async (row) => {
      console.log(row);
      this.row = row.row;
      //获取卡片信息
      let params = {
        user_id: row.row.user_id,
        followed_user_id: this.$store.state.baseStore.userInfo.id,
      };
      let cardMsg = await this.$store.dispatch(
        "register/getCollectionDetail",
        params
      );
      this.item = cardMsg.data;
      this.item.foreign_info = JSON.parse(cardMsg.data.foreign_info);
      this.show_more = false;
      this.dialogFormVisible = row.show;
    });
  },
  destroyed() {
    this.$GLOBALEVENT.$off("OPENUSERCard");
  },
  methods: {
    //  交换名片
    async ExchangeCard() {
      if (!this.USER_INFO.company_id) {
        //用户不存在公司
        this.$router.push("/register");
        return;
      }
      if (!this.USER_INFO.company_id) {
        //用户不存在公司
        this.$router.push("/register");
        return;
      }
      if (this.USER_INFO && !this.ENROLL_INFO.is_user_enroll) {
        //用户未报名会议
        this.$router.push("/register");
        return;
      }
      let params = {
        company_id: this.row.company_id,
        visit_company_id: this.$store.state.baseStore.userInfo.company_id,
        visit_user_id: this.$store.state.baseStore.userInfo.id,
        followed_user_id: this.row.user_id,
        follow_type: 2,
        source: this.PJSource,
      };

      let exchangeCard = await this.$store.dispatch(
        "register/exchangeCard",
        params
      );
      if (exchangeCard && exchangeCard.success) {
        this.$message.success(this.$t("exchangeCard_success"));
      }
      if (exchangeCard && exchangeCard.message == "already exist") {
        this.$message.warning(this.$t("card_exit"));
      }
    },
    handleContent(item) {
      this.$refs.vidoePlay.startPlay(item.aliyun_id);
    },
    copyText(text) {
      const input = document.createElement("textarea");
      document.body.appendChild(input);
      input.value = `${text}`;
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.$message.success(this.$t("copySuccess"));
    },
  },
};
</script>
<style lang="less" scoped>
.show_true {
  height: 565px;
}
.show_false {
  height: 358px;
  overflow-y: scroll;
}
.cardDialog {
  /deep/ .el-dialog__header {
    height: 0;
  }
  /deep/ .el-dialog__body {
    padding: 0;
  }
  .myBox {
    padding: 0 30px;
    padding-top: 44px;
    overflow-y: scroll;
    .show_box {
      h1 {
        font-size: 14px;
        font-weight: bold;
        color: #1f292e;
        padding-top: 21px;
        padding-bottom: 12px;
      }
      .green_box {
        display: flex;
        flex-wrap: wrap;
        span {
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #1ea89d;
          line-height: 17px;
          padding-left: 10px;
        }
      }
      .Personal_introduction {
        p {
          font-size: 12px;
          font-weight: 400;
          color: #1f292e;
          line-height: 17px;
        }
      }
      .lxfs {
        padding-top: 20px;
        div {
          margin-bottom: 25px;
          display: flex;
          align-items: center;
          label {
            width: 78px;
            font-size: 14px;
            font-weight: 400;
            color: #888888;
            margin-right: 24px;
          }
          span {
            font-size: 14px;
            font-weight: 800;
            color: #333333;
          }
          i {
            margin-left: 5px;
            font-size: 24px;
            cursor: pointer;
            color: #539cff;
          }
        }
      }
    }
  }
  .btn_footer_box {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    .btn_footer {
      width: 321px;
      // background-color: #1890FF;
      margin: auto;
    }
  }

  .i_rotate {
    transform: rotate(90deg);
  }
  /deep/ .el-dialog__footer {
    border-top: 1px solid #d8d8d8;
    height: 55px;
    padding: 16px;
    cursor: pointer;
    span,
    i {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #909399;
    }
  }
}
.videoList {
  display: flex;
  position: relative;
  width: 100%;
  overflow-x: scroll;
  .item {
    width: 131px;
    margin-right: 20px;
    .img {
      width: 131px;
      height: 91px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .img-wrap {
    position: relative;
    .mark {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 91px;
      line-height: 91px;
      text-align: center;
      .el-icon-video-play {
        font-size: 30px;
        color: #aaa;
      }
    }
    .mark:hover {
      background: rgba(0, 0, 0, 0.6);
    }
    .mark:hover .el-icon-video-play {
      color: #fff;
    }
  }
}
</style>