<template>
  <div class="cardBox" :style="{ backgroundImage: 'url(' + bgimg + ')' }">
    <div class="message">
      <img
        style="width: 72px; height: 72px; border-radius: 36px"
        :src="item.avatar ? item.avatar : DEFAULT_AVATAR"
        alt=""
      />
      <div>
        <h1 class="textOverflow" style="width: 300px">
          {{ item.user_name_en | priorFormat(item.user_name_zh, LOCALE) }}
        </h1>
        <small class="textOverflow" style="width: 300px">{{
          item.job_title_en | priorFormat(item.job_title_zh, LOCALE)
        }}</small>
        <span
          v-if="item.foreign_info && item.foreign_info.Industry_id"
          class="textOverflow"
          style="width: 300px"
          :title="item.foreign_info.Industry_id | industryFormat(LOCALE)"
        >
          {{ item.foreign_info.Industry_id | industryFormat(LOCALE) }}</span
        >
        <span
          v-else
          class="textOverflow"
          style="width: 300px"
          :title="item.industry | industryFormat(LOCALE)"
        >
          {{ item.industry | industryFormat(LOCALE) }}</span
        >
      </div>
    </div>
    <p
      class="dsc textOverflow"
      v-if="item.foreign_info && item.foreign_info.name_en"
      :title="
        item.foreign_info.name_en
          | priorFormat(item.foreign_info.name_zh, LOCALE)
      "
    >
      {{
        item.foreign_info.name_en
          | priorFormat(item.foreign_info.name_zh, LOCALE)
      }}
    </p>
    <p
      class="dsc textOverflow"
      v-else
      :title="item.company_name_en | priorFormat(item.company_name_zh, LOCALE)"
    >
      {{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}
    </p>
    <div class="infoBox">
      <div>
        <img :src="phoneImg" alt="" />
        <span class="line_1" v-if="USER_INFO.id">
          {{ item.mobile | textFormat }}</span
        >
        <span v-else class="cursor line_1" @click="$router.push('/sign')">{{
          $t("yetLogin_see")
        }}</span>
      </div>
      <div>
        <img :src="emailImg" alt="" />
        <span class="line_1" v-if="USER_INFO.id">{{
          item.email | textFormat
        }}</span>
        <span v-else class="cursor line_1" @click="$router.push('/sign')">{{
          $t("yetLogin_see")
        }}</span>
      </div>
      <div class="address">
        <img :src="location_png" alt="" />
        <div
          class="line_2"
          v-if="item.foreign_info && item.foreign_info.country"
          :title="
            item.foreign_info.country
              | countryCityFormat(
                item.foreign_info.region_en,
                item.foreign_info.region_zh,
                LOCALE
              )
          "
        >
          {{
            item.foreign_info.country
              | countryCityFormat(
                item.foreign_info.region_en,
                item.foreign_info.region_zh,
                LOCALE
              )
          }}
          <span
            v-if="item.foreign_info.address_en || item.foreign_info.address_zh"
            :title="
              item.foreign_info.address_en
                | priorFormat(item.foreign_info.address_zh, LOCALE)
            "
            >{{
              item.foreign_info.address_en
                | priorFormat(item.foreign_info.address_zh, LOCALE)
            }}</span
          >
        </div>
        <div
          class="line_2"
          v-else
          :title="
            item.country
              | countryCityFormat(item.region_en, item.region_zh, LOCALE)
          "
        >
          {{
            item.country
              | countryCityFormat(item.region_en, item.region_zh, LOCALE)
          }}
          <span
            v-if="item.address_en || item.address_zh"
            :title="item.address_en | priorFormat(item.address_zh, LOCALE)"
            >{{ item.address_en | priorFormat(item.address_zh, LOCALE) }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      bgimg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/bg_blue.png",
      phoneImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/phone.png",
      emailImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/email.png",
      location_png:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/location_png.png",
    };
  },
};
</script>
<style lang="less" scoped>
.cardBox {
  background-size: contain;
  background-repeat: no-repeat;
  height: 240px;
  padding: 15px;
  .message {
    height: 80px;
    display: flex;
    img {
      width: 80px;
      height: 80px;
    }
    div {
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      width: 100%;
      h1 {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
      }
      small {
        height: 22px;
        font-size: 12px;
        font-weight: 400;
        color: #a6d1ff;
        line-height: 22px;
      }
      span {
        height: 24px;
        font-size: 12px;
        font-weight: 400;
        color: #f4faff;
        line-height: 24px;
      }
    }
  }
  .dsc {
    cursor: pointer;
    margin: 10px 0;
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
  .infoBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div {
      display: flex;
      align-items: flex-start;
      img {
        padding-right: 9px;
      }
      .line_1 {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap !important;
        text-overflow: ellipsis;
      }
      .line_2 {
        cursor: pointer;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>